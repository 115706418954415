<template>
  <el-dialog
    class="dialog"
    :visible.sync="dialogLoginVisible"
    :before-close="handleClose"
    :show-close="false"
  >
    <div class="left">
      <div style="white-space: pre-wrap" class="left_title">{{ title }}</div>
    </div>
    <div class="main" v-show="logintype == 1">
      <div class="title">登录 Boser AI 账号</div>
      <div class="line_out" style="margin-top: 50px">
        <el-image
          style="width: 20px; height: 20px; margin-right: 5px"
          :src="require('../assets/img/login/line1.png')"
          fit="contain"
          class="icon"
        >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-input
          v-model="form.tel"
          class="line line1"
          placeholder="请输入手机号"
        >
        </el-input>
      </div>
      <div class="line_out" style="margin-top: 50px; line-height: 50px">
        <el-image
          style="width: 160px; height: 100%; top: 0; left: 0"
          :src="uuImg"
          @click="getCodeReq"
          fit="contain"
          class="icon"
        >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-input
          v-model="form.uuCode"
          class="line line1"
          placeholder="请输入校验码"
          style="width: 200px; margin-left: 168px"
        >
        </el-input>
      </div>
      <div
        class="line_out"
        :class="{ code_error: !codeFlag }"
        style="margin-top: 38px"
      >
        <el-image
          style="width: 20px; height: 20px; margin-right: 5px"
          :src="require('../assets/img/login/line2.png')"
          fit="contain"
          class="icon"
        >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <el-input
          v-model="form.code"
          maxlength="6"
          class="line line2"
          placeholder="请输入验证码"
          @input="codeChange"
        ></el-input>

        <el-button
          type="text"
          style="position: absolute; right: 30px; color: #006fff; top: 5px"
          :disabled="bVerification"
          @click="sendCode"
          v-if="countDown == '发送验证码'"
        >
          {{ countDown != "发送验证码" ? countDown + "s" : countDown }}
        </el-button>
        <el-button
          type="text"
          style="position: absolute; right: 30px; color: #797989; top: 5px"
          :disabled="bVerification"
          v-else-if="countDown != '发送验证码'"
        >
          {{ countDown != "发送验证码" ? countDown + "s" : countDown }}
        </el-button>
      </div>

      <div style="margin-top: 22px">
        <el-checkbox v-model="form.days" fill="#006FFF"></el-checkbox>
        <span style="margin-left: 4px">账号14天免登录</span>
      </div>

      <b-button
        block
        pill
        variant="primary"
        size="lg"
        style="margin-top: 25px"
        @click="onSubmit"
        >登录</b-button
      >
      <div class="protocol">
        点击登录表示同意我们的
        <span
          style="color: #006fff; cursor: pointer"
          @click="jump('https://boserai.com/html/UserArguement.html')"
          >《服务协议》</span
        >和<span
          style="color: #006fff; cursor: pointer"
          @click="jump('https://boserai.com/html/PrivacyPolicy.html')"
          >《隐私条款》</span
        >
      </div>
      <!-- <div  class="d-flex" style="margin-top: 14px;align-items: center;">
        <div style="height: 1px;background: #7D7D9B;flex: 1;"></div>
        <div style="margin: 0 9px;font-weight: 500;color: #7D7D9B;font-size: 16px;">或</div>
        <div style="height: 1px;background: #7D7D9B;flex: 1;"></div>
      </div>
      <div  class="d-flex" style="margin-top: 14px; justify-content: center;">
        <el-image style="width: 42px; height: 42px;cursor: pointer;"
          @click="logintype = 2 " 
          :src="require('../assets/img/login/wechat.png')"
           fit="contain" >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
      </div> -->
    </div>
    <div class="main" v-show="logintype == 2">
      <div class="title d-flex" style="align-items: center">
        <el-image
          style="width: 28px; height: 28px; cursor: pointer; margin-right: 7px"
          @click="logintype = 1"
          :src="require('../assets/img/login/leftarrow.png')"
          fit="contain"
        >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        微信登录
      </div>
      <div style="padding: 33px">
        <wxlogin
          theme=""
          id="wxcode"
          v-if="appid && redirect_uri"
          :appid="appid"
          scope="snsapi_login"
          :redirect_uri="redirect_uri"
          :style="style"
          :href="href"
          :state="state"
        ></wxlogin>

        <!-- <qrcodevue :value="'weixin://wxpay/bizpayurl?pr=Cn1pSz6zz'" :size="300"></qrcodevue> -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { sms_req, login_req, code_req, wx_login_req } from "@/api/user";
import { getUUID } from "@/utils/bose";
import wxlogin from "vue-wxlogin";

export default {
  props: {
    dialogLoginVisible: {
      type: Boolean,
      default: false,
    },
  },
  components: { wxlogin },

  data() {
    return {
      title: "上海博色\n信息科技有限公司",
      input: "",
      form: {
        tel: null,
        code: null,
        cnl: localStorage.getItem("cc")
          ? localStorage.getItem("cc") + "-pc"
          : "none-pc",
        bind: localStorage.getItem("bd_vid_url")
          ? localStorage.getItem("bd_vid_url")
          : undefined,
        qhclickid: localStorage.getItem("qhclickid")
          ? localStorage.getItem("qhclickid")
          : undefined,
        days: true,
        uuCode: "",
      },
      bVerification: false,
      countDown: "发送验证码", // 倒计时
      codeFlag: true,
      logintype: 1,
      appid: "wxfcb0ac7daa4527ab",
      redirect_uri: encodeURIComponent(
        "https://boserai.com/boserai/common/wx/"
      ),
      state: getUUID(),
      href: "", // 自定义样式链接
      style: "black",
      href: "data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7fQouaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMzAwcHg7IGhlaWdodDogMzAwcHh9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHtkaXNwbGF5OiBub25lO30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQ==",
      uuid: "",
      uuImg: "",
    };
  },
  methods: {
    async getCodeReq() {
      let res = await code_req();
      let { success, data } = res;
      if (success) {
        this.uuid = data.uuid;
        this.uuImg = data.img;
      }
    },
    handleClose(done) {
      this.resetForm();
      this.bVerification = false;
      this.countDown = "发送验证码"; // 倒计时
      this.codeFlag = true;
      this.$emit("update:dialogLoginVisible", false); // 传递更新数值
    },
    resetForm() {
      this.form = {
        tel: null,
        code: null,
        cnl: localStorage.getItem("cc")
          ? localStorage.getItem("cc") + "-pc"
          : "none-pc",
        bind: localStorage.getItem("bd_vid_url")
          ? localStorage.getItem("bd_vid_url")
          : undefined,
        qhclickid: localStorage.getItem("qhclickid")
          ? localStorage.getItem("qhclickid")
          : undefined,
        days: true,
      };
      this.bVerification = false; // 节流
    },
    async onSubmit() {
      if (!this.validationPhone) {
        this.$toast.open({
          message: "请检查手机号格式",
          position: "top",
          duration: 3000,
          queue: true,
          type: "error",
        });
        return;
      }
      if (this.form.uuCode == "") {
        this.$toast.open({
          message: "请输入校验码",
          position: "top",
          duration: 3000,
          queue: true,
          type: "error",
        });
        this.codeFlag = false;
        return;
      }
      if (!this.form.code) {
        this.$toast.open({
          message: "请检查验证码格式",
          position: "top",
          duration: 3000,
          queue: true,
          type: "error",
        });
        this.codeFlag = false;
        return;
      }

      // console.log( this.form)
      const { success, data } = await login_req(this.form);
      if (success && data) {
        localStorage.setItem("x-token", data);

        this.$toast.open({
          message: "登录成功",
          position: "top",
          duration: 3000,
          type: "success",
        });
        this.$store.commit("user/change_token");
        this.$store.dispatch("message/changeMesNumAct");
        this.handleClose();
        this.resetForm();
      } else {
        this.codeFlag = false;
      }
    },
    async sendCode() {
      if (!this.validationPhone) {
        this.$toast.open({
          message: "请检查手机号格式",
          position: "top",
          duration: 3000,
          queue: true,
          type: "error",
        });
        return;
      }
      if (this.form.uuCode == "") {
        this.$toast.open({
          message: "请输入校验码",
          position: "top",
          duration: 3000,
          queue: true,
          type: "error",
        });
      }
      if (this.bVerification) return;
      this.bVerification = true;
      this.countDown = 60;
      const { success, data } = await sms_req({
        tel: this.form.tel,
        key: this.uuid,
        code: this.form.uuCode,
      });
      if (success === true && data === true) {
        this.$toast.open({
          message: "发送验证码",
          position: "top",
          duration: 3000,
          type: "success",
        });
        let countDown = 59;
        const auth_time = setInterval(() => {
          this.countDown = countDown--;
          if (this.countDown <= 0) {
            this.bVerification = false;
            this.countDown = "发送验证码";
            clearInterval(auth_time);
          }
        }, 1000);
      } else {
        this.bVerification = false;
        this.countDown = "发送验证码";
      }
    },
    codeChange() {
      this.codeFlag = true;
    },
    async wxLoginMethod(state) {
      const { data, success } = await wx_login_req({ state: state });
      if (success && data) {
        localStorage.setItem("x-token", data);

        this.$toast.open({
          message: "登录成功",
          position: "top",
          duration: 3000,
          type: "success",
        });
        this.$store.commit("user/change_token");
        this.$store.dispatch("message/changeMesNumAct");
        let obj = {};
        obj.router = "home";
        this.$router.replace({
          name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: obj,
        });
      } else {
        this.codeFlag = false;
      }
    },
    jump(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    validationPhone() {
      var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.form.tel)) {
        return false;
      } else {
        return true;
      }
    },
  },
  async mounted() {
    this.resetForm();
    this.getCodeReq();
    this.bVerification = false;
    this.countDown = "发送验证码"; // 倒计时
    this.codeFlag = true;
    if (this.$route.query.result == "success" && this.$route.query.state) {
      this.wxLoginMethod(this.$route.query.state);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #006fff;
  border-color: #006fff;
  border-radius: 50%;
}
::v-deep .el-checkbox__input .el-checkbox__inner {
  border-color: #fff;
  border-radius: 50%;
}

::v-deep .el-dialog {
  width: 689px;
  height: 520px;
  position: relative;
  // background-color: palegreen;
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  background-image: url("../assets/img/login/bg.png") !important;

  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height:600px;*/
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
::v-deep .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}
//::v-deep .el-dialog
// ::v-deep .el-dialog::-webkit-scrollbar-thumb {
//   background-color: #000;
// }

::v-deep .el-dialog .left {
  width: 320px;
  height: 438px;
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 98;
  .left_title {
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 29px;
    position: absolute;
    left: 26px;
    top: 144px;
  }
}
::v-deep .el-dialog .main {
  width: 450px;
  height: 520px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  // background-image:url("../../public/assets/img/login/bg1.png") !important ;
  padding: 40px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 33px;
  backdrop-filter: blur(8px);

  .title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin-top: 26px;
    position: relative;
  }

  .line_out {
    position: relative;
    .icon {
      position: absolute;
      top: 15px;
      left: 18px;
    }
  }
  .line {
    width: 370px;
    height: 50px;
    background: rgba(34, 35, 55, 0);
    border-radius: 33px;
    box-sizing: border-box;
  }
  .el-input__inner {
    height: 50px;
    border-radius: 33px;
    border: 2px solid #7d7d9b;
    background: rgba(34, 35, 55, 0);
    padding: 0 10px 0 50px;
    color: #fff;
  }

  .el-input__inner:focus {
    border-color: #006fff;
  }
  .protocol {
    margin-top: 22px;
  }
  .code_error .el-input__inner {
    border-color: red;
    color: red;
  }
}
</style>
