import request from '@/utils/request'
import axios from 'axios'

export function md_list(query) {
  return request({
    url:  '/md/list',
    method: 'get',
    params: query
  })
}

export function st_list(query) {
  return request({
    url:  '/st/list',
    method: 'get',
    params: query
  })
}

export function feature_list(query) {
  return request({
    url:  '/feature/list',
    method: 'get',
    params: query
  })
}

export function feature_name(query) {
  return request({
    url:  '/feature/names',
    method: 'get',
    params: query
  })
}

export function add_task_req(data) {
  return request({
    url:  '/task/add',
    method: 'post',
    data: data,
  })
}

 export function img_page_req(data) {
  return request({
    url:  '/img/page',
    method: 'post',
    data: data,
  })
}


export function task_interrupt_req(query) {
  return request({
    url:  '/task/interrupt/' + query ,
    method: 'get',
    // params: query
  })
}

//
export function task_status_req(query) {
  return request({
    url:  '/task/info/' + query   ,
    method: 'get',
  })
}
 


//task_running_req
export function task_running_req() {
  return request({
    url:  '/task/running'    ,
    method: 'get',
  })
}

export function task_undoTask_req(query) {
  return request({
    url:  '/task/undoTask'    ,
    method: 'get',
    params: query
  })
}

export function img_del(data) {
  return request({
    url:  '/img/del?taskId=' + data,
    method: 'GET',
    // query: data,
  })
}

export function painting_removeByTid(data) {
  return request({
    url:  '/painting/removeByTid?taskId=' + data,
    method: 'GET',
    // query: data,
  })
}

// /folder/remove