<template>
  <b-row class="navbar">
    <b-col cols="8" style="padding-left: 0; padding-right: 0" class="nav-l">
      <div style="display: flex; align-items: center; position: relative">
        <a href="https://boserai.com/">
          <el-image
            style="
              width: 36px;
              height: 36px;
              margin-right: 8px;
              cursor: pointer;
            "
            alt="博色AI"
            :src="require('../assets/img/logo.png')"
            fit="contain"
            @click.stop="homeBack"
          >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
        </a>
        <span style="cursor: pointer" @click="homeBack">Boser AI 创作平台</span>

        <div class="alertcont" v-if="alertcontVis">
          <div style="flex: 1">Ctrl+D立即将Boser AI收藏到书签栏，获取5积分</div>

          <el-image
            style="width: 30px; height: 30px; cursor: pointer"
            alt="博色AI"
            :src="require('../assets/img/nav/close.png')"
            fit="contain"
            @click.stop="closealertcont"
          >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>
        </div>
      </div>

      <div class="search_cont" v-if="!ai_qa_schemeqa">
        <el-image
          style="width: 60px; height: 26px; margin-right: 7px; cursor: pointer"
          alt="博色AI"
          :src="require('../assets/img/nav/aisearch.png')"
          fit="contain"
        >
          <div slot="placeholder" class="image-slot"></div>
        </el-image>
        <div class="input_cont">
          <el-image
            style="width: 20px; height: 20px; cursor: pointer"
            alt="博色AI"
            :src="require('../assets/img/nav/searchicon.png')"
            fit="contain"
            @click="aisearch_click"
          >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>

          <el-input
            v-model="searchvalue"
            placeholder="AI搜索，只给你有用的结果"
            style="color: #666"
          ></el-input>
        </div>
        <div class="search_btn" @click="aisearch_click">搜一搜</div>
      </div>
    </b-col>
    <b-col
      cols="4"
      style="text-align: right; padding-left: 0; padding-right: 0"
    >
      <span
        v-show="iconshow"
        class="d-flex"
        style="
          justify-content: flex-end;
          align-items: center;
          flex-wrap: nowrap;
        "
      >
        <el-popover
          trigger="hover"
          popper-class="popover"
          placement="top-end"
          width="400"
        >
          <el-carousel
            height="38px"
            style="
              width: calc(100% - 7px);
              overflow-y: hidden;
              height: 38px;
              overflow-x: hidden;
              line-height: 38px;
              color: #fff;
            "
            indicator-position="none"
            arrow="never"
            direction="vertical"
          >
            <el-carousel-item v-for="(item, idx) in aiNewsList" :key="idx">
              <div
                style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 12px;
                "
                @click="ainewsclick(item, idx)"
              >
                {{ item.title }}
              </div>
            </el-carousel-item>
          </el-carousel>
          <div
            slot="reference"
            style="
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #222337;
              display: flex;
              align-items: center;
              padding-left: 8px;
              margin-right: 10px;
            "
          >
            <el-image
              style="width: 24px; height: 24px; margin-right: 5px"
              :src="require('../assets/img/home/trumpet.png')"
              fit="contain"
            >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
          </div>
        </el-popover>
        <b-icon
          class="d-lg-none d-md-none d-sm-block d-block needsclick"
          icon="list"
          font-scale="1.4"
          v-b-toggle.sidebar-no-header
          style="color: #fff"
        ></b-icon>
        <div
          class="d-flex needsclick"
          style="color: #fff; align-items: center"
          v-if="!tokenVuex"
        >
          <el-button
            type="primary"
            round
            @click="login_click"
            style="
              cursor: pointer;
              margin-left: 5px;
              background: linear-gradient(90deg, #ff8900 0%, #f22d10 100%);
              margin-right: 18px;
            "
            >充值</el-button
          >
          <GroupScan style="margin-right: 16px"></GroupScan>
          <!-- <infoCenterPop style="margin-right: 16px;"  :goLogin="true"></infoCenterPop> -->
          <el-image
            @click="login_click"
            style="
              width: 30px;
              height: 30px;
              margin-right: 1px;
              cursor: pointer;
              margin-bottom: -2px;
            "
            :src="require('../assets/img/nav/info.png')"
            fit="contain"
          >
            <div slot="placeholder" class="image-slot"></div>
          </el-image>

          <el-button
            type="primary"
            round
            @click="login_click"
            style="cursor: pointer; margin-left: 5px; background-color: #006fff"
            >登录</el-button
          >
        </div>
        <template v-else>
          <div
            v-if="ac_data.vip"
            class="d-flex"
            style="align-items: center; margin-right: 24px"
          >
            <el-image
              style="
                width: 30px;
                height: 30px;
                margin-right: 1px;
                cursor: pointer;
                margin-bottom: 2px;
              "
              :src="require('../assets/img/membericon.png')"
              fit="contain"
            >
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
            <span
              style="
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e3a048;
                line-height: 30px;
                height: 30px;
              "
              >会员至{{ ac_data.endDate.split(" ")[0] }}</span
            >
          </div>

          <el-button
            type="primary"
            round
            @click="rechargeShowClick"
            style="
              cursor: pointer;
              margin-left: 5px;
              background: linear-gradient(90deg, #ff8900 0%, #f22d10 100%);
              margin-right: 18px;
            "
            >充值</el-button
          >

          <!-- <el-image style="width: 190px; height: 34px;margin-right: 18px;cursor: pointer;margin-bottom: 2px;" 
              :src="require('../assets/img/nav/cuxiao.png')"
              fit="contain"  @click="rechargeShowClick ">
            <div slot="placeholder" class="image-slot"></div>
          </el-image> -->

          <!-- <el-image style="width: 100px; height: 34px;margin-right: 18px;cursor: pointer;margin-bottom: 2px;" 
              :src="require('../assets/img/nav/cuxiao2.png')"
              fit="contain"  @click="rechargeShowClick ">
            <div slot="placeholder" class="image-slot"></div>
          </el-image> -->

          <GroupScan style="margin-right: 16px"></GroupScan>
          <infoCenterPop style="margin-right: 16px"></infoCenterPop>
          <PersonalPop
            :acData="ac_data"
            @ListenPerson="ListenPerson"
            @popShow="popShow"
            v-if="personPopSHow"
            :key="timestamp"
          ></PersonalPop>
        </template>
      </span>
    </b-col>
    <b-sidebar id="sidebar-no-header" title="BS AI" shadow>
      <div class="p-3" style="padding: 5px">
        <nav class="mb-3" style="padding: 5px">
          <b-nav vertical>
            <b-nav-item
              v-for="(item, index) in navdata"
              :key="index"
              :to="item.route"
              class="nav-line needsclick"
            >
              <span>{{ item.title }} </span>
            </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>

    <Recharge
      :dialogRechargeVisible.sync="rechargeShow"
      v-if="rechargeShow"
      :rechargeType="rechargeType"
    ></Recharge>
    <Record
      :dialogRecordVisible.sync="recordShow"
      :dialogRecordType.sync="recordType"
      :dialogFeedbackVisible.sync="feedbackShow"
      v-if="recordShow"
    ></Record>
    <FeedBack
      :dialogFeedbackVisible.sync="feedbackShow"
      v-if="feedbackShow"
    ></FeedBack>
    <CropperDialog
      :dialogUploadAvaVisible.sync="uploadAvaShow"
      :option="cropoption"
      v-if="uploadAvaShow"
    ></CropperDialog>
    <AccountSettings
      :dialogAccountSettingsShow.sync="accountSettingsShow"
      v-if="accountSettingsShow"
      @userChangeListen="userChangeListen"
    ></AccountSettings>
    <AiNewsPop
      :ainewsVisible.sync="ainewsVisible"
      v-if="ainewsVisible"
      :aiNewsList="aiNewsList"
      :ainewsIdx="ainewsIdx"
      @changeaiidx="changeaiidx"
      @openAinews="openAinews"
    ></AiNewsPop>
  </b-row>
</template>

<script>
import common from "../utils/common";
import {
  sms_req,
  login_req,
  login_out_res,
  user_account_req,
  event_log_req,
} from "@/api/user";
import { msg_typeCount_req } from "@/api/message";
import Recharge from "@/components/Recharge";
import Record from "@/components/Record";
import FeedBack from "@/components/FeedBack";
import CropperDialog from "@/components/CropperDialog";
import GroupScan from "@/components/GroupScan";
import infoCenterPop from "@/components/infoCenterPop";
import PersonalPop from "@/components/PersonalPop";
import AiNewsPop from "@/components/AiNewsPop";
import AccountSettings from "@/components/AccountSettings";
import { getUUID } from "@/utils/bose";
import md5 from "js-md5";
import {
  growth_tasks_req,
  growth_publish_req,
  growth_level_req,
} from "@/api/task";
import { qa_template_list_req } from "@/api/qa";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    iconshow: {
      type: Boolean,
      default: true,
    },
    needUpdate: {
      type: Number,
      default: 1,
    },
    needRecharge: {
      type: Number,
    },
    needRechargeShow: {
      type: Boolean,
    },
  },
  components: {
    Recharge,
    Record,
    FeedBack,
    CropperDialog,
    GroupScan,
    PersonalPop,
    AccountSettings,
    infoCenterPop,
    AiNewsPop,
  },
  name: "Navbar",
  data() {
    return {
      navdata: [],
      form: {
        tel: null,
        code: null,
      },
      bVerification: false,
      countDown: "发送验证码", // 倒计时
      ac_data: {}, //账户信息
      rechargeShow: false,
      rechargeType: 8,
      recordShow: false,
      recordType: "1",
      feedbackShow: false,
      uploadAvaShow: false,
      personPopSHow: true,
      timestamp: undefined,
      aiNewsList: [],
      ainewsVisible: false,
      ainewsIdx: undefined,
      cropoption: {
        img: "", // 裁剪图片的地址
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        // autoCropWidth: 572, // 默认生成截图框宽度
        // autoCropHeight: 572, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        original: true, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },

      alertcontVis: false,
      searchvalue: undefined,
      aiqa_list: [],
      ai_qa_schemeqa: false,
      ai_qa_schemeqa_data: undefined,
    };
  },
  created() {
    if (this.getQueryVariable("cc")) {
      localStorage.setItem("cc", this.getQueryVariable("cc"));
    }
    if (this.getQueryVariable("bd_vid") || this.getQueryVariable("qhclickid")) {
      localStorage.setItem("bd_vid_url", window.location.href);
      // localStorage.setItem( "bd_vid_url",   'https://boserai.com?bd_vid=uANBIyIxUhNLgvw-I-tkP10vnH04g1cvg1DLnj0snWRvnWmdrHm'  )
    }
    if (this.getQueryVariable("qhclickid")) {
      localStorage.setItem("qhclickid", this.getQueryVariable("qhclickid"));
    }
  },
  mounted() {
    if (this.$route.path == "/ai-qa-schemeqa") {
      this.ai_qa_schemeqa = true;
    }

    let _this = this;
    // this.navdata = common.getNavData()
    this.navdata = this.$i18n.messages[this.$i18n.locale].getNavData;
    this.timestamp = Date.parse(new Date());
    let page = undefined;
    if (
      this.$route.name == "home" &&
      this.getQueryVariable("path") == "ai-qa-scheme"
    ) {
      page = "ai-qa-scheme";
    } else if (this.$route.name == "home") {
      page = "home";
    } else if (this.$route.name == "aiQa") {
      page = "qa";
    } else if (this.$route.name == "textImage") {
      page = "textimage";
    } else if (this.$route.name == "imageToImage") {
      page = "imageimage";
    } else if (this.$route.name == "explore") {
      page = "explore";
    } else if (this.$route.name == "Personal") {
      page = "personal";
    } else {
      page = this.$route.name;
    }

    if (this.tokenVuex) {
      //如果登录了 去获取信息
      this.getUserAccountInfo().then(() => {
        //获取账户信息
        if (page) {
          this.event_active_log(page);
        }
        if (page == "ai-qa-scheme") {
          this.$router.replace({
            name: "aiQaScheme", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          });
        }

        document.onkeydown = function (e) {
          var keyCode = e.keyCode || e.which || e.charCode;
          var ctrlKey = e.ctrlKey || e.metaKey;
          if (ctrlKey && keyCode == 68) {
            growth_publish_req({
              type: 7,
            }).then((res) => {
              growth_tasks_req({ type: 7 }).then((res1) => {
                if (res1.data[0].total == res1.data[0].count) {
                  setTimeout(() => {
                    _this.$toast.open({
                      message: "收藏成功，5积分已到账",
                      position: "top",
                      duration: 3000,
                      type: "success",
                    });
                  }, 1000);
                }
              });
            });
          }
          // e.preventDefault();
          // return false;
        };
      });

      this.getMsgCount();
    } else {
      if (page) {
        this.event_active_log(page);
      }

      if (page == "ai-qa-scheme") {
        this.$router.replace({
          name: "aiQaScheme", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        });
      }
    }

    if (!localStorage.getItem("alertcontVis")) {
      this.alertcontVis = true;
    }

    qa_template_list_req(undefined, undefined).then((res) => {
      res.data.forEach((val) => {
        if (val.title == "AI搜索") {
          this.ai_qa_schemeqa_data = val;
        }
      });
    });
    this.$axios
      .get(
        "https://innews.infohubnews.app/v1/channels/news?cty=CN&lang=zh&offset=0&limit=100&ch=telegram&c=finance"
      )
      .then((res) => {
        if (res.data.Result) {
          this.aiNewsList = res.data.Result;
        }
      });
  },
  beforeDestroy() {
    this.personPopSHow = false;
  },
  computed: {
    validationPhone() {
      var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(this.userForm.phone)) {
        return false;
      } else {
        return true;
      }
    },
    tokenVuex() {
      // 存储位置： store.state.{模块名,在store/index.js中module下的}.{state中定义的属性}
      return this.$store.state.user.token;
    },
    accountSettingVuex() {
      return this.$store.state.user.accountSetting;
    },
    accountSettingsShow: {
      get() {
        return this.$store.state.user.accountSetting;
      },
      set(newVal) {},
    },
  },
  methods: {
    ainewsclick(item, idx) {
      // window.open( item.link ,  "_blank" )  ;
      this.ainewsIdx = idx;
      this.ainewsVisible = true;
    },
    changeaiidx(idx) {
      this.ainewsIdx = idx;
    },
    openAinews(idx) {
      this.ainewsVisible = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.ainewsIdx = idx;
          this.ainewsVisible = true;
        }, 30);
      });
    },
    event_active_log(page) {
      let cnl = localStorage.getItem("cc")
        ? localStorage.getItem("cc") + "-pc"
        : "none-pc";
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID();
      var ts = Date.parse(new Date());
      let detail_str = JSON.stringify({ page: page });
      let sign = md5(
        "cnl" +
          cnl +
          "detail" +
          detail_str +
          "event" +
          "active" +
          "ts" +
          ts +
          "uid" +
          uid +
          "yEBgmO69"
      );
      event_log_req({
        // category : '' ,
        cnl: cnl,
        detail: detail_str,
        event: "active",
        ts: ts,
        uid: uid,
        sign: sign,
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        name: null,
        code: null,
      };
      this.bVerification = false; // 节流

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      // console.log( this.form)
      const { success, data } = await login_req(this.form);
      if (success && data) {
        localStorage.setItem("x-token", data);

        this.$toast.open({
          message: "登录成功",
          position: "top",
          duration: 3000,
          type: "success",
        });
        this.$bvModal.hide("modal-center");
        this.$store.commit("user/change_token");
      }

      this.resetForm();
    },
    async sendCode() {
      if (this.bVerification) return;
      this.bVerification = true;
      const { success, data } = await sms_req({
        tel: this.form.tel,
      });
      if (success === true && data === true) {
        this.$toast.open({
          message: "发送验证码",
          position: "top",
          duration: 3000,
          type: "success",
        });
        let countDown = 59;
        const auth_time = setInterval(() => {
          this.countDown = countDown--;
          if (this.countDown <= 0) {
            this.bVerification = false;
            this.countDown = "发送验证码";
            clearInterval(auth_time);
          }
        }, 1000);
      } else {
        this.bVerification = false;
        this.countDown = "发送验证码";
      }
    },
    async login_out() {
      localStorage.removeItem("x-token");
      this.$store.commit("user/change_token");
      this.$toast.open({
        message: "成功退出",
        position: "top",
        duration: 3000,
        type: "success",
      });

      this.$router.push({
        name: "home", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
        params: {
          unlogin: true,
        },
      });
      if (!this.tokenVuex) {
        return;
      }

      const { success, data } = await login_out_res();
      if (success === true && data === true) {
        localStorage.removeItem("x-token");
        this.$store.commit("user/change_token");
      }
      this.resetForm();
    },
    async getUserAccountInfo() {
      const { data, success } = await user_account_req();
      if (success) {
        if (data.ext) {
          data.ext = JSON.parse(data.ext);
        }
        this.ac_data = data;
      }
      this.timestamp = Date.parse(new Date());
    },
    dropdown_show_method() {
      if (this.tokenVuex) {
        //如果登录了 去获取信息
        this.getUserAccountInfo(); //获取账户信息
      }
    },
    homeBack() {
      this.$router.push({
        path: `/`,
      });
    },
    config_square_img() {
      this.$router.push({
        path: `/square_config`,
      });
    },
    login_click() {
      this.$emit("update:dialogLoginVisible", true); // 传递更新数值
    },
    rechargeShowClick() {
      this.rechargeType = 8;
      this.rechargeShow = true;
      let cnl = localStorage.getItem("cc")
        ? localStorage.getItem("cc") + "-pc"
        : "none-pc";
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID;
      var ts = Date.parse(new Date());
      let sign = md5(
        "cnl" +
          cnl +
          "event" +
          "recharge_click" +
          "ts" +
          ts +
          "uid" +
          uid +
          "yEBgmO69"
      );
      event_log_req({
        // category : '' ,
        cnl: cnl,
        event: "recharge_click",
        ts: ts,
        uid: uid,
        sign: sign,
      });
    },
    recordShowClick(type) {
      this.recordType = type;
      this.recordShow = true;
    },
    feedbackClick() {
      this.feedbackShow = true;
    },
    getFile(file, fileList, scope) {
      // console.log
      const typeArry = [
        ".jpg",
        ".png",
        ".bmp",
        ".JPG",
        ".PNG",
        ".BMP",
        ".gif",
        ".GIF",
        ".jpeg",
        ".JPEG",
      ];
      const type = file.name.substring(file.name.lastIndexOf("."));
      const isImage = typeArry.indexOf(type) > -1;
      if (!isImage) {
        this.$toast.open({
          message: "上传文件必须为图片",
          position: "top",
          duration: 3000,
          type: "error",
        });
        return;
      }

      let imgSize = Number(file.size / 1024 / 1024);
      if (imgSize > 2) {
        this.$toast.open({
          message: "图片最大2MB哦，请重试",
          position: "top",
          duration: 3000,
          type: "error",
        });
        return;
      }

      if (fileList && fileList.length >= 2) {
        fileList.shift();
      }
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(async () => {
        // base64方式
        // this.option.img = await fileByBase64(file.raw)
        this.cropoption.img = URL.createObjectURL(file.raw);
        this.uploadAvaShow = true;
      });
    },
    ListenPerson(obj) {
      if (obj.num == 1) {
        this.login_out();
      } else if (obj.num == 2) {
        this.rechargeType = obj.rechargeType ? obj.rechargeType : 8;
        this.rechargeShow = true;
      } else if (obj.num == 3) {
        // this.accountSettingsShow = true ;
        this.$store.commit("user/change_accountSetting", true);
      } else if (obj.num == 4) {
        this.feedbackClick();
      } else if (obj.num == 5) {
        this.recordShowClick("2");
      } else if (obj.num == 6) {
        this.recordShowClick("1");
      } else if (obj.num == 7) {
        let obj = {};
        obj.router = "personal";
        obj.uid = this.ac_data.uid;
        this.$router.push({
          name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: obj,
        });
      } else if (obj.num == 8) {
        let obj = {};
        obj.router = "taskCenter";
        this.$router.push({
          name: "test", // ⚠️注：这里不能用path路径，只能用name【请对照router.js中的路由规则中的name项】，否则取不到传过去的数据
          params: obj,
        });
      }
    },
    popShow() {
      this.getUserAccountInfo(); //获取账户信息
    },
    userChangeListen() {
      this.timestamp = Date.parse(new Date());
      this.getUserAccountInfo(); //获取账户信息
    },
    event_log() {
      let cnl = localStorage.getItem("cc")
        ? localStorage.getItem("cc") + "-pc"
        : "none-pc";
      let uid = this.ac_data.uid ? this.ac_data.uid : getUUID;
      var ts = Date.parse(new Date());
      let sign = md5(
        "cnl" +
          cnl +
          "detail" +
          "" +
          "event" +
          "recharge_click" +
          "ts" +
          ts +
          "uid" +
          uid +
          "yEBgmO69"
      );
      event_log_req({
        // category : '' ,
        cnl: cnl,
        detail: "",
        event: "recharge_click",
        ts: ts,
        uid: uid,
        sign: sign,
      });
    },
    getMsgCount() {
      this.$store.dispatch("message/changeMesNumAct");
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    dialogAccountSettingsMed(flag) {
      console.log(flag);
      this.accountSettingsShow = flag;
      this.$forceUpdate();
    },
    growthTasks() {
      growth_tasks_req().then((res) => {
        res.data.forEach((val) => {
          let _type = val.type;
          let str = "taskType" + _type;
          this[str].ext = val.ext;
          this[str].status = val.status;
        });
      });
    },
    closealertcont() {
      this.alertcontVis = false;
      localStorage.setItem("alertcontVis", 1);
    },
    aisearch_click() {
      if (this.searchvalue) {
        try {
          this.ai_qa_schemeqa_data.chatTemplatePromptModelList[0].text =
            this.searchvalue;
          this.ai_qa_schemeqa_data.needsearch = true;
          this.$router.push({
            path: "/ai-qa-schemeqa",
            query: {
              params: JSON.stringify(this.ai_qa_schemeqa_data),
            },
          });
        } catch (error) {}
      }
    },
  },
  watch: {
    // 如果 `question` 发生改变，这个函数就会运行
    rechargeShow: function (newQuestion, oldQuestion) {
      this.getUserAccountInfo(); //获取账户信息
      let _num = this.needUpdate + 1;
      this.$emit("update:needUpdate", _num); // 传递更新数值
    },
    uploadAvaShow: function (newQuestion, oldQuestion) {
      if (!newQuestion) {
        this.$refs["my-upload"].clearFiles();
        this.getUserAccountInfo(); //获取账户信息
      }
    },
    tokenVuex() {
      this.getUserAccountInfo(); //获取账户信息
    },
    accountSettingVuex(newQuestion) {
      if (newQuestion) {
        this.accountSettingsShow = true;
      }
    },
    needRecharge(newQuestion) {
      this.rechargeShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dropdown-toggle {
  line-height: 1;
}
::v-deep .dropdown-menu {
  background-color: #1b1d2e;
  padding: 8px;
  top: 5px !important;
  .dropdown-item {
    color: #9a9ab7;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9a9ab7;
    width: 100%;
    box-sizing: border-box;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background: #252739;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 500;
    color: #ddddea;
  }
}

::v-deep .dropdown-menu:before {
  display: block;
  content: "";
  border-width: 6px 6px 6px 6px;
  border-style: solid;
  border-color: transparent transparent #2c2f42 transparent;

  /* 定位 */
  position: absolute;
  right: 8px;
  top: -12px;
}

::v-deep .btn {
  padding: 0;
}

.navbar {
  margin-left: 0;
  margin-right: 0;
  min-height: calc(2em + 1vw);
  height: 66px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #111225;
  padding: 15px 18px;
  box-sizing: border-box;
  z-index: 1999;
  .title {
    font-size: calc(1em + 1vw);
    color: #fff;
  }
  .nav-l {
    display: flex;
    align-items: center;
  }
}

.home-icon {
  color: #fff;
}

a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}

.nav-line > a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin-right: 15px;
}

.alertcont {
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 462px;
  height: 46px;
  line-height: 26px;
  background: #222337;
  border-radius: 4px;
  border: 1px solid #2c2f42;
  padding: 10px 5px 10px 18px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  display: flex;
}
.alertcont:before {
  content: "";
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #222337;
  position: absolute;
  left: 80px;
  top: -10px;
}

.search_cont {
  height: 38px;
  display: flex;
  align-items: center;
  margin-left: 65px;
  .input_cont {
    width: 418px;
    height: 38px;
    background: #222337;
    border-radius: 19px;
    padding: 0 14px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .search_btn {
    width: 72px;
    height: 34px;
    background: #006fff;
    border-radius: 17px;
    text-align: center;
    line-height: 34px;
    font-size: 15px;
    color: #ffffff;
    line-height: 34px;
    margin-left: 10px;
    cursor: pointer;
  }
}

::v-deep .input_cont .el-input__inner {
  background-color: #222337;
  border: none;
  color: #999;
  height: 32px;
  line-height: 32px;
}
// .modal-dialog {
//     max-width: 300px;
//     margin: 1.75rem auto;
// }
</style>
