<template>
  <!-- v-if="taskId" -->
  <div
    v-if="taskId"
    class="status"
    :style="
      type != 2
        ? isShow
          ? 'bottom: 80px'
          : ''
        : type != 3
        ? isShow
          ? 'bottom: 80px'
          : ''
        : ''
    "
    @click="showClick"
  >
    <el-image
      style="width: 180px; height: 180px; position: relative; z-index: 1"
      :src="
        require(`../assets/img/${
          type == 2 ? 'success' : type == 3 ? 'error' : 'loading'
        }.gif`)
      "
    />
    <div
      class="status-text"
      :style="
        type == 3
          ? 'color:#EF4545'
          : type != 2
          ? isShow
            ? 'bottom: -60px'
            : ''
          : type != 3
          ? isShow
            ? 'bottom: -60px'
            : ''
          : ''
      "
    >
      {{ statusText }}
      <div
        class="status-box"
        v-if="type == 1 && param.index > 0 && param.total > 0 && isShow"
      >
        排队{{ param.index }}/{{ param.total }}
      </div>
      <el-progress
        v-else-if="type == 1 && param.progress && isShow"
        type="circle"
        :percentage="
          param.progress - 0 ? (param.progress * 100).toFixed(0) - 0 : 1
        "
        text-color="#fff"
        color="#006FFF"
        :width="70"
        style="margin-top: 10px"
      ></el-progress>
      <div
        class="status-box"
        v-else-if="
          type == 1 && isShow && !param.progress && !param.total && !param.index
        "
      >
        任务状态获取中
      </div>
    </div>
  </div>
</template>

<script>
import { task_status_req } from "@/api/taskImage";
export default {
  data() {
    return {
      statusText: "排队生成中",
      type: 1,
      param: {
        index: null,
        total: null,
        progress: null,
      },
      timeout: null,
      taskId: null,
      isShow: false,
      link: null,
    };
  },
  watch: {
    type(newVal) {
      if (newVal >= 2) {
        this.isShow = false;
      }
    },
  },
  mounted() {
    if (localStorage.getItem("taskId")) {
      this.taskId = localStorage.getItem("taskId");
      this.link = localStorage.getItem("link");
      this.getStatus();
      this.setTimer();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    showClick() {
      if (this.type == 2 || this.type == 3) {
        this.$router.push({ path: this.link, query: { type: "echo" } });
        localStorage.removeItem("taskId");
        localStorage.removeItem("link");
      } else {
        this.isShow = !this.isShow;
      }
    },
    async getStatus() {
      let res = await task_status_req(this.taskId);
      if (res.success) {
        let { obj, status } = res.data;
        this.type = status;
        this.param = obj;
        if (status == 2) {
          this.statusText = `任务已完成`;
          this.clear();
        } else if (status == 3) {
          this.statusText = `任务失败`;
          this.clear();
        } else if (status == 6) {
          this.statusText = `任务已取消`;
          this.clear();
        } else {
          this.statusText = `排队生成中`;
        }
      }
    },
    setTimer() {
      this.timeout = setInterval(() => {
        this.getStatus();
      }, 1000);
    },
    clear() {
      clearTimeout(this.timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  color: #fff;
  position: fixed;
  z-index: 99999;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
  .status-text {
    width: 140px;
    max-height: 140px;
    background: #3a3c60;
    border-radius: 8px;
    position: absolute;
    left: 20px;
    bottom: 20px;
    padding: 6px 12px;
    box-sizing: border-box;
    text-align: center;
    .status-box {
      height: 80px;
      text-align: center;
      color: #006fff;
      line-height: 80px;
    }
  }
}
</style>
