<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "Layout",
  data() {
    return {
      // 需要缓存的组件名称
      keepAlive: [],
    };
  },
  created() {
    this.checkRouteKeep(this.$route);
    this.$router.beforeEach((to, from, next) => {
      const { keepAlive, fromPath, componentName } = to.meta;
      if (keepAlive) {
        // 如果不是从详情页跳转到列表页，就清除列表页的缓存
        if (
          Array.isArray(fromPath) &&
          fromPath.every((item) => item != from.path)
        ) {
          this.keepAlive = this.keepAlive.filter(
            (item) => item != componentName
          );
        }
        setTimeout(() => this.checkRouteKeep(to), 100);
      }
      next();
    });
  },
  methods: {
    checkRouteKeep(route) {
      const { keepAlive, componentName } = route.meta;
      if (keepAlive && this.keepAlive.every((item) => item != componentName)) {
        this.keepAlive.push(componentName);
      }
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  // height: 100%;
  background-color: #0b0b1f;
  color: #fff;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #212529;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.btn-box {
  width: 220px;
  height: 44px;
  border-radius: 8px;
  line-height: 44px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
  margin-bottom: 16px;
  background: linear-gradient(315deg, #006fff 0%, #72e3f5 100%);
}
</style>
