var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.taskId)?_c('div',{staticClass:"status",style:(_vm.type != 2
      ? _vm.isShow
        ? 'bottom: 80px'
        : ''
      : _vm.type != 3
      ? _vm.isShow
        ? 'bottom: 80px'
        : ''
      : ''),on:{"click":_vm.showClick}},[_c('el-image',{staticStyle:{"width":"180px","height":"180px","position":"relative","z-index":"1"},attrs:{"src":require(`../assets/img/${
        _vm.type == 2 ? 'success' : _vm.type == 3 ? 'error' : 'loading'
      }.gif`)}}),_c('div',{staticClass:"status-text",style:(_vm.type == 3
        ? 'color:#EF4545'
        : _vm.type != 2
        ? _vm.isShow
          ? 'bottom: -60px'
          : ''
        : _vm.type != 3
        ? _vm.isShow
          ? 'bottom: -60px'
          : ''
        : '')},[_vm._v(" "+_vm._s(_vm.statusText)+" "),(_vm.type == 1 && _vm.param.index > 0 && _vm.param.total > 0 && _vm.isShow)?_c('div',{staticClass:"status-box"},[_vm._v(" 排队"+_vm._s(_vm.param.index)+"/"+_vm._s(_vm.param.total)+" ")]):(_vm.type == 1 && _vm.param.progress && _vm.isShow)?_c('el-progress',{staticStyle:{"margin-top":"10px"},attrs:{"type":"circle","percentage":_vm.param.progress - 0 ? (_vm.param.progress * 100).toFixed(0) - 0 : 1,"text-color":"#fff","color":"#006FFF","width":70}}):(
        _vm.type == 1 && _vm.isShow && !_vm.param.progress && !_vm.param.total && !_vm.param.index
      )?_c('div',{staticClass:"status-box"},[_vm._v(" 任务状态获取中 ")]):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }